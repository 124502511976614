define("auth-frontend/services/permissions-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    permissions: {
      "admin": {
        "312": "agency_transfer",
        "386": "copy_forms"
      },
      "vector": {
        "186": "quote_access",
        "196": "quote_application",
        "198": "quote_anonymous",
        "199": "quote_startapplication",
        "200": "quote_submitapplication",
        "201": "quote_releaseapplication",
        "202": "quote_issueapplication",
        "203": "quote_rejectapplication",
        "207": "evaluation_access",
        "208": "quote_acceptviolation",
        "209": "quote_customratingtier",
        "210": "quote_revertapplication",
        "211": "vector_perreps",
        "218": "vector_webpayments",
        "220": "binder_access",
        "224": "hide_blank_applications",
        "227": "vector_user_only_access",
        "252": "revert_to_quote",
        "253": "change_producer",
        "255": "vector_payments",
        "318": "hazardhubaccess"
      },
      "translation": {
        "257": "view_translations",
        "258": "edit_translations"
      },
      "navigator": {
        "184": "navigator_access",
        "187": "reports_access",
        "188": "reports_renewal",
        "189": "reports_balance",
        "190": "reports_claims",
        "191": "reports_policy",
        "192": "reports_agent",
        "204": "reports_premiumnotice",
        "205": "reports_policylistdata",
        "206": "navigator_copytoquote",
        "213": "navigator_changerequest",
        "214": "navigator_webpayments",
        "217": "navigator_restrict_premium",
        "221": "reports_locationdetail",
        "222": "navigator_claim_attachment",
        "225": "navigator_submit_claim",
        "228": "navigator_create_claim",
        "230": "new_claim_system",
        "311": "al3_download_permission",
        "339": "manual_download"
      },
      "dashboard": {
        "185": "appsfiles_access",
        "195": "dashboard_access",
        "212": "vector_emin",
        "215": "mips_webinquiry",
        "216": "zendesk",
        "219": "dashboard_news",
        "223": "imaging_dashboard",
        "226": "activity_access"
      },
      "claims": {
        "5": "claims_user",
        "6": "delete_claim",
        "7": "claim_detail_update",
        "8": "claim_detail_view",
        "9": "claim_admin_access",
        "10": "submit_claim_form_config",
        "11": "payment_edit",
        "12": "reserve_edit",
        "13": "reserve_view",
        "14": "view_notes",
        "15": "add_notes",
        "16": "view_reminders",
        "17": "add_reminders_for_self",
        "18": "add_reminders_for_others",
        "19": "view_reminders_for_others",
        "20": "view_activity",
        "21": "assign_tags",
        "22": "assign_substatus",
        "23": "access_documents",
        "24": "loss_recovery_access",
        "25": "payment_view",
        "26": "payment_account",
        "27": "claim_create",
        "28": "search",
        "29": "app_menu",
        "30": "claims",
        "31": "dashboard",
        "32": "payment_void",
        "229": "claims_access",
        "240": "payment_adjustment",
        "241": "report_loss_run",
        "259": "view_payment_settings",
        "260": "edit_payment_settings",
        "261": "filetrac",
        "315": "edit_electronic_payment_settings",
        "316": "view_electronic_payment_settings",
        "350": "view_claim_transaction_report",
        "351": "edit_adjuster_settings",
        "387": "edit_electronic_status"
      },
      "webinquiry": {
        "165": "view_commission_statements",
        "166": "view_book_of_business",
        "167": "view_payment_history",
        "168": "submit_change_request",
        "169": "make_payment",
        "170": "reports_access",
        "171": "block_line_item_premium",
        "172": "update_payment_options",
        "173": "download_active_codes",
        "174": "company_options",
        "175": "claim_attachment",
        "176": "copy_to_quote",
        "177": "submit_claim",
        "178": "create_claim",
        "179": "new_claim_system",
        "180": "claims_report",
        "238": "agent_download",
        "246": "datatransform_user",
        "314": "hide_deductible"
      },
      "imaging board": {
        "129": "attachments_agency_visibility",
        "130": "attachments_delete",
        "131": "annotations_edit",
        "132": "email",
        "133": "empty_recycle_bin",
        "134": "attachments_folders",
        "135": "update",
        "136": "related_links",
        "137": "create",
        "138": "annotations_view",
        "139": "read",
        "140": "attachments_recycle_bin"
      },
      "imaging forms": {
        "286": "attachments_folders",
        "287": "attachments_agency_visibility",
        "288": "attachments_delete",
        "289": "update",
        "290": "create",
        "291": "read",
        "292": "attachments_recycle_bin",
        "293": "empty_recycle_bin",
        "294": "related_links",
        "295": "email",
        "296": "annotations_view",
        "297": "annotations_edit"
      },
      "imaging auditors": {
        "274": "attachments_folders",
        "275": "attachments_agency_visibility",
        "276": "attachments_delete",
        "277": "update",
        "278": "create",
        "279": "read",
        "280": "attachments_recycle_bin",
        "281": "empty_recycle_bin",
        "282": "related_links",
        "283": "email",
        "284": "annotations_view",
        "285": "annotations_edit"
      },
      "imaging accountants": {
        "298": "attachments_folders",
        "299": "attachments_agency_visibility",
        "300": "attachments_delete",
        "301": "update",
        "302": "create",
        "303": "read",
        "304": "attachments_recycle_bin",
        "305": "empty_recycle_bin",
        "306": "related_links",
        "307": "email",
        "308": "annotations_view",
        "309": "annotations_edit"
      },
      "imaging default_policy": {
        "57": "attachments_agency_visibility",
        "58": "attachments_delete",
        "59": "annotations_edit",
        "60": "email",
        "61": "empty_recycle_bin",
        "62": "attachments_folders",
        "63": "update",
        "64": "related_links",
        "65": "create",
        "66": "annotations_view",
        "67": "read",
        "68": "attachments_recycle_bin"
      },
      "imaging need_to_print": {
        "262": "attachments_folders",
        "263": "attachments_agency_visibility",
        "264": "attachments_delete",
        "265": "update",
        "266": "create",
        "267": "read",
        "268": "attachments_recycle_bin",
        "269": "empty_recycle_bin",
        "270": "related_links",
        "271": "email",
        "272": "annotations_view",
        "273": "annotations_edit"
      },
      "imaging company": {
        "117": "attachments_agency_visibility",
        "118": "attachments_delete",
        "119": "annotations_edit",
        "120": "email",
        "121": "empty_recycle_bin",
        "122": "attachments_folders",
        "123": "update",
        "124": "related_links",
        "125": "create",
        "126": "annotations_view",
        "127": "read",
        "128": "attachments_recycle_bin"
      },
      "imaging reports": {
        "93": "attachments_agency_visibility",
        "94": "attachments_delete",
        "95": "annotations_edit",
        "96": "email",
        "97": "empty_recycle_bin",
        "98": "attachments_folders",
        "99": "update",
        "100": "related_links",
        "101": "create",
        "102": "annotations_view",
        "103": "read",
        "104": "attachments_recycle_bin"
      },
      "imaging default_claim": {
        "105": "attachments_agency_visibility",
        "106": "attachments_delete",
        "107": "annotations_edit",
        "108": "email",
        "109": "empty_recycle_bin",
        "110": "attachments_folders",
        "111": "update",
        "112": "related_links",
        "113": "create",
        "114": "annotations_view",
        "115": "read",
        "116": "attachments_recycle_bin"
      },
      "imaging conversion": {
        "322": "attachments_folders",
        "323": "attachments_agency_visibility",
        "324": "attachments_delete",
        "325": "update",
        "326": "create",
        "327": "read",
        "328": "attachments_recycle_bin",
        "329": "empty_recycle_bin",
        "330": "related_links",
        "331": "email",
        "332": "annotations_view",
        "333": "annotations_edit"
      },
      "imaging reference": {
        "153": "attachments_agency_visibility",
        "154": "attachments_delete",
        "155": "annotations_edit",
        "156": "email",
        "157": "empty_recycle_bin",
        "158": "attachments_folders",
        "159": "update",
        "160": "related_links",
        "161": "create",
        "162": "annotations_view",
        "163": "read",
        "164": "attachments_recycle_bin"
      },
      "imaging management": {
        "141": "attachments_agency_visibility",
        "142": "attachments_delete",
        "143": "annotations_edit",
        "144": "email",
        "145": "empty_recycle_bin",
        "146": "attachments_folders",
        "147": "update",
        "148": "related_links",
        "149": "create",
        "150": "annotations_view",
        "151": "read",
        "152": "attachments_recycle_bin"
      },
      "imaging claim": {
        "81": "attachments_agency_visibility",
        "82": "attachments_delete",
        "83": "annotations_edit",
        "84": "email",
        "85": "empty_recycle_bin",
        "86": "attachments_folders",
        "87": "update",
        "88": "related_links",
        "89": "create",
        "90": "annotations_view",
        "91": "read",
        "92": "attachments_recycle_bin"
      },
      "imaging agency": {
        "69": "attachments_agency_visibility",
        "70": "attachments_delete",
        "71": "annotations_edit",
        "72": "email",
        "73": "empty_recycle_bin",
        "74": "attachments_folders",
        "75": "update",
        "76": "related_links",
        "77": "create",
        "78": "annotations_view",
        "79": "read",
        "80": "attachments_recycle_bin"
      },
      "imaging policy": {
        "33": "attachments_agency_visibility",
        "34": "attachments_delete",
        "35": "annotations_edit",
        "36": "email",
        "37": "empty_recycle_bin",
        "38": "attachments_folders",
        "39": "update",
        "40": "related_links",
        "41": "create",
        "42": "annotations_view",
        "43": "read",
        "44": "attachments_recycle_bin"
      },
      "imaging doc_templates": {
        "45": "attachments_agency_visibility",
        "46": "attachments_delete",
        "47": "annotations_edit",
        "48": "email",
        "49": "empty_recycle_bin",
        "50": "attachments_folders",
        "51": "update",
        "52": "related_links",
        "53": "create",
        "54": "annotations_view",
        "55": "read",
        "56": "attachments_recycle_bin"
      },
      "activity": {
        "340": "reminders_all",
        "384": "manage_subscription_others",
        "385": "manage_subscriptions_blocked",
        "388": "get_daily_subscribers"
      },
      "config": {
        "321": "add_agency",
        "344": "edit_accounts",
        "348": "product_api_access"
      },
      "agent-download": {
        "256": "ivans_agent"
      },
      "search": {
        "320": "upload_to_search"
      },
      "lender notifications": {
        "319": "create_emin"
      },
      "imaging config": {
        "232": "imaging_backup",
        "310": "imaging_to_imaging_conversion",
        "342": "overwrite_username"
      },
      "payments": {
        "243": "bills",
        "244": "due",
        "245": "pay",
        "248": "policy-settings",
        "249": "payments",
        "250": "vector_payments",
        "251": "vendor-balance-lookup",
        "317": "transaction_report",
        "347": "outbound_payments"
      },
      "rolodex": {
        "338": "rolodex_admin"
      },
      "auth": {
        "1": "edit_group_permissions",
        "2": "create_new_groups",
        "3": "manage_users",
        "4": "change_password",
        "233": "edit",
        "234": "view",
        "235": "assign_multiple_agencies",
        "236": "export_permissions",
        "237": "require_mfa",
        "239": "spoof_user",
        "313": "manage_company_permissions",
        "337": "change_password_blocked",
        "349": "spoof_company"
      },
      "site": {
        "183": "cpanel_access",
        "193": "authenticate_agent",
        "194": "help_edit",
        "197": "usersettings_access",
        "231": "appsfiles_access"
      },
      "insured portal": {
        "343": "ip_settings"
      },
      "rate": {
        "181": "view_rate_sets",
        "182": "edit_rate_sets"
      },
      "workflow": {
        "242": "view_others_reminders",
        "247": "view_others_subscriptions"
      },
      "spectrum": {
        "334": "spectrum_user",
        "335": "spectrum_accounting",
        "336": "spectrum_rater"
      },
      "sample_b": {
        "392": "1one",
        "393": "2two",
        "394": "3three",
        "395": "2one",
        "396": "3two",
        "397": "4three"
      },
      "sample_a": {
        "389": "one",
        "390": "two",
        "391": "three"
      }
    }
  });

  _exports.default = _default;
});